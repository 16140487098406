import mergeI18n from '~/utils/merge-i18n';
import { richText, stripRichTextWrapperTag } from '~/utils/html';
import qa from '~/i18n/messages/fr-fr.questions-answers.json';
import forms from '~/i18n/messages/fr-fr.forms.json';

const dataI18n = Object.assign({}, qa, forms);

const formatDate = (date, i18n) => {
  return new Intl.DateTimeFormat(i18n.locale, { dateStyle: 'long' }).format(
    new Date(date)
  );
};

const formatSubtitle = (author, date, i18n) => {
  const subtitle = [];

  if (author || date) {
    subtitle.push('<span class="subtitle">');
    author && subtitle.push(author);
    author && date && subtitle.push(' • ');
    date && subtitle.push(formatDate(date, i18n));
    subtitle.push('</span>');

    return subtitle.join('');
  } else {
    return '';
  }
};

export default ({ primary }, pageData, context) => {
  mergeI18n(context.app.i18n, dataI18n);
  const {
    app: { i18n },
  } = context;
  const marketData = pageData.market ? pageData.market.data : pageData;
  const marketTitle = marketData?.breadcrumb_title || marketData?.title;
  const items = primary.module_questions_answers?.data?.items || null;

  const title = richText(primary.module_questions_answers.data?.title);

  return {
    module: {
      title: title || i18n.t('questions-answers.title'),
      items: items
        .filter((item) => item.question.length && item.answer.length)
        .map((item) => {
          const headSubtitle = formatSubtitle(
            item.question_author,
            item.question_date,
            i18n
          );
          const textSubtitle = formatSubtitle(
            item.answer_author,
            item.answer_date,
            i18n
          );

          return {
            withBackground: true,
            head: `${richText(item.question)}${headSubtitle}`,
            text: `${stripRichTextWrapperTag(item.answer)}${textSubtitle}`,
          };
        }),
      ctaLabel: i18n.t('questions-answers.question'),
      showQuestionCta: primary.show_question_cta,
    },
    formTitle: i18n.t('questions-answers.question'),
    form: {
      narrow: true,
      sections: [
        {
          id: 'question',
          title: i18n.t('questions-answers.your-question'),
          inputs: [
            {
              name: 'message',
              validator: 'textarea',
              value: '',
              required: true,
              field: {
                id: 'message',
                label: i18n.t('forms.entries.question.label'),
                type: 'textarea',
              },
              messages: {
                required: i18n.t('forms.entries.question.messages.required'),
              },
            },
          ],
        },
        {
          id: 'informations',
          title: i18n.t('questions-answers.your-details'),
          inputs: [
            {
              name: 'name',
              value: '',
              validator: 'text',
              required: true,
              field: {
                id: 'name',
                label: i18n.t('forms.entries.name.label'),
              },
              messages: {
                required: i18n.t('forms.entries.name.messages.required'),
                invalid: i18n.t('forms.entries.name.messages.invalid'),
              },
            },
            {
              name: 'email',
              value: '',
              validator: 'email',
              required: false,
              field: {
                id: 'email',
                label: i18n.t('forms.entries.email.label'),
                type: 'email',
              },
              messages: {
                required: i18n.t('forms.entries.email.messages.required'),
                invalid: i18n.t('forms.entries.email.messages.invalid'),
              },
            },
            {
              name: 'market',
              value: marketTitle,
              field: {
                type: 'hidden',
              },
            },
            {
              name: 'type',
              value: 'question',
              field: {
                type: 'hidden',
              },
            },
            {
              name: 'recipient',
              value: primary.mailto_recipient,
              field: {
                type: 'hidden',
              },
            },
          ],
        },
      ],
      buttons: [
        {
          label: i18n.t('cta.send'),
          cta: {
            type: 'submit',
          },
        },
      ],
    },
    confirmation: {
      icon: 'confirmation',
      title: i18n.t('questions-answers.confirmation-title'),
      text: primary.confirmation_text.length
        ? richText(primary.confirmation_text)
        : i18n.t('questions-answers.confirmation-text'),
      primaryCta: {
        isBack: true,
        label: i18n.t('cta.close'),
      },
    },
  };
};
